import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {ColumnState} from "ag-grid-community";
import {FilterID} from "filters";
import UserPreferencesCacheService from '../common/services/userPreferencesCache.service'
import {RootState} from "./store";
import {IPreset} from "../common/components/range-date-picker/presets/presets.props";
import {PageID} from "./filters.slice";

export type IPagePresetsID = 'admin/offers/offers'
    | 'admin/offers'
    | 'admin/users/partners'
    | 'admin/billing/marketer-funding-monitoring'
    | 'admin/dashboard/marketer-funding-monitoring'
    | 'admin/users/marketers/id/credit'
    | 'admin/billing/partner-transactions'
    | 'admin/billing/partner-payments'
    | 'admin/billing/marketer-balance'
    | 'admin/billing/marketer-transactions'
    | 'admin/users/marketers'
    | 'admin/users/admin'
    | 'admin/users/partners/merging/merged'
    | 'admin/users/partners/merging/non-merged'
    | 'admin/verticals/vertical-groups'
    | 'admin/verticals/verticals-all'
    | 'admin/tools/payouts'
    | 'admin/partner-registration'
    | 'admin/tools/notifications'
    | 'admin/tools/scheduled-reports'
    | 'admin/tools/scheduled-reports-log'
    | 'admin/tools/tags'
    | 'admin/tools/visibility-presets'
    | 'admin/tools/features'
    | 'admin/tools/banners'
    | 'admin/tools/tracking-pixels'
    | 'admin/tools/events-adjustments/events'
    | 'admin/tools/events-adjustments/automated-adjustment'
    | 'admin/tools/metis'
    | 'admin/reports/budgets-report'
    | 'admin-dashboard-partner-budgets-report'
    | 'admin/dashboard/engine-marketer-view/budgets'
    | "marketer/campaigns/budget-groups"

export interface IFilter {
    selected: any[]
    include?: boolean
    match?: 'any' | 'all' | 'empty'
    dateRangeFrom?: Date | null | undefined
    dateRangeTo?: Date | null | undefined
    option? : string
}

export interface IDatePreset {
    dateTo: Date | null | undefined
    dateFrom: Date | null | undefined
    compareDateTo?: string,
    compareDateFrom?: string,
    datePreset?: IPreset,
    compareDatePreset?: IPreset
}

interface IInitialState {
    [key: string]: {
        table: ColumnState[],
        filters: {
            [key: string]: IFilter
        },
        date?: IDatePreset,
        pagination?: {
            perPage: number,
            page: number
        },
        search?: string,
        loading?: boolean
    },
}

interface IFetchUserPreferencesParams {
    pageId: IPagePresetsID,
    filters?: {
        [key: string]: IFilter
    },
}

const initialState: IInitialState = {

}

export const fetchUserPreferences = createAsyncThunk<any, IFetchUserPreferencesParams, {state: RootState}>(
    'presets/fetchUserPreferences',
    async ({pageId, filters= {}}, {getState, dispatch}) => {
        const state = getState()
        const user_id = state.auth.user.id
        const token = state.auth.token
        dispatch(setInitialFilters({pageId, filters}))
        dispatch(setLoading({pageId, loading: true}))
        const {data} = await UserPreferencesCacheService.fetchUserPreferencesCache(token, user_id, pageId)
        return {
            data: JSON.parse(data.data),
            pageID: pageId
        }
    }
)

export const saveUserPreferences = createAsyncThunk<any, IPagePresetsID, {state: RootState}>(
    'presets/saveUserPreferences',
    async (pageId, {getState}) => {
        const state = getState()
        const user_id = state.auth.user.id
        const token = state.auth.token
        await UserPreferencesCacheService.addUserPreferencesCache(token, user_id, pageId, JSON.stringify(state.userPreferences[pageId]))
    }
)

export const saveUserTablePreferences = createAsyncThunk<any, {pageId: IPagePresetsID, data: ColumnState[]}, {state: RootState}>(
    'presets/saveUserTablePreferences',
    async (
        {pageId, data},
        {getState, dispatch}) => {
        const state = getState()
        const user_id = state.auth.user.id
        const token = state.auth.token
        dispatch(setUserTablePreferences({pageId, data: data}))
        const newState = {...state.userPreferences[pageId], table: data}
        await UserPreferencesCacheService.addUserPreferencesCache(
            token,
            user_id,
            pageId,
            JSON.stringify(newState)
        )
    }
)

export const saveUserDatePreferences = createAsyncThunk<any, {pageId : IPagePresetsID, date: IDatePreset }, {state : RootState}>(
    'presets/saveUserTablePreferences',
    async (
        {pageId, date}, {getState}) => {
        const state = getState()
        const userID = state.auth.user.id
        const token = state.auth.token
        const newState = {...state.userPreferences[pageId], date : date}
        await UserPreferencesCacheService.addUserPreferencesCache(token, userID, pageId,
            JSON.stringify(newState))
})

export const saveUserSearchPreferences = createAsyncThunk<any, {pageId : IPagePresetsID, value: string }, {state : RootState}>(
    'presets/saveUserTablePreferences',
    async (
        {pageId, value}, {getState, dispatch}) => {
        const state = getState()
        const userID = state.auth.user.id
        const token = state.auth.token
        const newState = {...state.userPreferences[pageId], search: value}
        dispatch(setUserSearchPreferences({pageId, value}))
        await UserPreferencesCacheService.addUserPreferencesCache(token, userID, pageId,
            JSON.stringify(newState))
    })

export const saveUserPaginationPreferences = createAsyncThunk<any, {pageId : IPagePresetsID, pagination: {perPage: number, page: number} }, {state : RootState}>(
    'presets/saveUserTablePreferences',
    async (
        {pageId, pagination}, {getState, dispatch}) => {
        const state = getState()
        const userID = state.auth.user.id
        const token = state.auth.token
        const newState = {...state.userPreferences[pageId], pagination}
        dispatch(setUserPaginationPreferences({pageId, pagination}))
        await UserPreferencesCacheService.addUserPreferencesCache(token, userID, pageId,
            JSON.stringify(newState))
    })

export const saveUserFilterPreferences = createAsyncThunk<any, {pageId : IPagePresetsID, filters: {[key: string]: IFilter} }, {state : RootState}>(
    'presets/saveUserTablePreferences',
    async (
        {pageId, filters}, {getState, dispatch}) => {
        const state = getState()
        const userID = state.auth.user.id
        const token = state.auth.token
        const newState = {...state.userPreferences[pageId], filters}
        dispatch(setUserFilterPreferences({pageId, filters}))
        await UserPreferencesCacheService.addUserPreferencesCache(token, userID, pageId,
            JSON.stringify(newState))
    })

const userPreferencesSlice = createSlice(
    {
        name: 'userPreferences',
        initialState: initialState,
        reducers: {
            setUserTablePreferences(state, action: PayloadAction<{pageId: IPagePresetsID, data: ColumnState[]}>){
                state[action.payload.pageId] = {...state[action.payload.pageId], table: action.payload.data}
            },
            setUserSearchPreferences(state, action: PayloadAction<{pageId: IPagePresetsID, value: string}>){
                state[action.payload.pageId] = {...state[action.payload.pageId], search: action.payload.value}
            },
            setUserPaginationPreferences(state, action: PayloadAction<{pageId: IPagePresetsID, pagination: {perPage: number, page: number}}>){
                state[action.payload.pageId] = {...state[action.payload.pageId], pagination: action.payload.pagination}
            },
            setUserFilterPreferences(state, action: PayloadAction<{pageId: IPagePresetsID, filters: {[key: string]: IFilter}}>){
                state[action.payload.pageId] = {...state[action.payload.pageId], filters: action.payload.filters}
            },
            setLoading(state, action: PayloadAction<{pageId: IPagePresetsID, loading: boolean}>){
                state[action.payload.pageId] = {...state[action.payload.pageId], loading: action.payload.loading}
            },
            //FILTERS
            setInitialFilters(state, action: PayloadAction<{pageId: IPagePresetsID, filters: {[key: string]: IFilter}}>){
                const {pageId, filters} = action.payload
                // console.log(state[pageId].filters)
                state[pageId] = {filters: filters, table: []}
            },
            addFilterChip(state, action: PayloadAction<{pageId: IPagePresetsID, filterId: FilterID}>){
                const {pageId, filterId} = action.payload
                state[pageId].filters[filterId] = {
                    selected: [],
                    include: true,
                    match: 'any'
                }
            },
            removeFilterChip(state, action: PayloadAction<{pageId: IPagePresetsID, filterId: FilterID}>){

                const {pageId, filterId} = action.payload
                // delete state[pageId].filters[filterId]
                const {[filterId]: removed, ...newState} = state[pageId].filters
                state[pageId].filters = newState
            },
            clearAllFilterChips(state, action: PayloadAction<{pageId: IPagePresetsID}>){
                const {pageId} = action.payload
                const newFilters = {...state[pageId].filters}
                Object.keys(newFilters).forEach(key => {
                    newFilters[key].selected = []
                    newFilters[key].include = true
                    newFilters[key].match = 'any'
                    newFilters[key].dateRangeFrom = null
                    newFilters[key].dateRangeTo = null
                    // this is for approved Spend filter for budget report
                    // in case there is another filter that has option we need to change this
                    newFilters[key].option = "60"
                })
                state[pageId].filters = {...newFilters}
            },
            setFilterValue(state, action: PayloadAction<{
                filterId: FilterID,
                pageId: IPagePresetsID,
                filter: IFilter
            }>){
                const {filterId, pageId, filter} = action.payload
                state[pageId].filters= {...state[pageId].filters, [filterId]: filter}
            },
            clearSelectedValues(state, action: PayloadAction<{pageId: IPagePresetsID, filterId: FilterID}>){
                const {pageId, filterId} = action.payload
                state[pageId].filters[filterId].selected = []
                state[pageId].filters[filterId].dateRangeTo = null
                state[pageId].filters[filterId].dateRangeFrom = null
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(
                    fetchUserPreferences.fulfilled,
                    (state, action) => {
                        state[action.payload.pageID] = {...state[action.payload.pageID], ...action.payload.data, loading: false}
                    }
                )

        }
    }

)

export default userPreferencesSlice.reducer
export const {
    setUserTablePreferences,
    setUserSearchPreferences,
    setUserPaginationPreferences,
    setFilterValue,
    // setInclude,
    // setMatch,
    clearAllFilterChips,
    clearSelectedValues,
    removeFilterChip,
    setInitialFilters,
    addFilterChip,
    setUserFilterPreferences,
    setLoading
} = userPreferencesSlice.actions

