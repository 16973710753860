import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const referrerReal = (function () {
    let referrer = apiBaseUrl;
    let exploded = window.location.pathname.split('/');
    exploded.forEach((e,i)=>{
        if(i==1)
        {
            e = e+'#';
        }

        referrer += e;

        if(i<exploded.length-1)
        {
            referrer += '/'
        }

    });
    return referrer;
})();

export const api = axios.create({
    baseURL: apiBaseUrl,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Angular-Referrer': referrerReal!,
    }
});

export const filterApi = axios.create({
    baseURL: [
        process.env.REACT_APP_API_BASE_URL,
        process.env.REACT_APP_API_URL,
        process.env.REACT_APP_API_V1_URL,
        process.env.REACT_APP_API_FILTERS,
    ].join('/')
})