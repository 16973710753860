import {eachDayOfInterval, format, startOfWeek} from "date-fns";

export const getWeekDaysShortFormat = () => {
    const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 }); // Monday of the current week
    const weekEnd = new Date(weekStart); // Clone the Monday date
    weekEnd.setDate(weekEnd.getDate() + 6); // End of the week

    const weekDays = eachDayOfInterval({ start: weekStart, end: weekEnd });

    return weekDays.map(day => format(day, 'eee'));
}