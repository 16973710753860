import {eachMonthOfInterval, format} from "date-fns";

export const getMonthNamesShortFormat = () => {

    const yearStart = new Date(); // Current date
    yearStart.setMonth(0); // January of the current year
    yearStart.setDate(1); // First day of January

    const yearEnd = new Date(); // Current date
    yearEnd.setMonth(11); // December of the current year
    yearEnd.setDate(31); // Last day of December

    const monthsInYear = eachMonthOfInterval({ start: yearStart, end: yearEnd });

    return monthsInYear.map(month => format(month, 'MMM'));


}