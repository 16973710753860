import React from "react";
import {ThemeProvider, DefaultTheme} from "styled-components";

export const theme: DefaultTheme = {
    colors: {
        pureWhite: '#FFFFFF',
        neutralGrey: {
            1: '#FBFBFB',
            2: '#F9F9F9',
            3: '#F1F1F1'
        },
        coolGray: {
            1: '#F7F9FA',
            2: '#F3F7F8',
            3: '#E9ECED',
            4: '#D0D5D7',
            5: '#BEC1C2',
            6: '#B2B8BB',
        },
        performBlue: {
            1: '#E7F9FB',
            2: '#C0F3FA',
            3: '#63E7F9',
            4: '#0ED6F1',
            5: '#25C0D5', //Brand Color
            6: '#0496AA',
            7: '#005B67',
            8: '#00505B',
            9: '#1F2A2C',
            10: '#0099AE',
            11: '#007383',
            12: '#F0FCFF'
        },
        deepBlue: {
            1: '#DFE7EB',
            2: '#CBD6DB',
            3: '#ACBBC1',
            4: '#8799A2',
            5: '#5A686F',
            6: '#455056',
            7: '#31383B',
            8: '#272C2F',
            9: '#1D2122'
        },
        performOrange: {
            1: '#FFEEEA',
            2: '#FFBCAD',
            3: '#FE7E60',
            4: '#E96142',
            5: '#FF5E38',
            6: '#EF4A24',
            7: '#DD4421',
            8: '#7B1E08'
        },
        juicedOrange: {
            50: '#FFF9F0',
            1: '#FFF3E0',
            2: '#FFE8C9',
            3: '#FFC87D',
            4: '#FFB248',
            5: '#FE9800',
            6: '#E48900',
            7: '#B96F00',
            8: '#714400',
            9: '#503000'
        },
        warning: {
            1: '#FEFAEC',
            2: '#FFEBA5',
            3: '#F9D34C',
            4: '#CCAC3A',
            5: '#F3C315',
            6: '#DBAA00',
            7: '#AC8111'
        },
        critical: {
            1: '#FFD9DD',
            2: '#FF7A8A',
            3: '#FA1E3A',
            4: '#B91428',
            5: '#7E000F'
        },
        success: {
            3: '#0CB087',
            4: '#0A755A',
            5: '#043E2F'
        },
        positiveCell: {
            25_50: '#D9FBDF',
            50_75: '#AAFBB7',
            75_100: '#71F987',
            100_1000: '#34E24F',
            1000: '#13CC20'
        },
        negativeCell: {
            1_25: '#FFF2F3',
            25_50: '#FFDFE1',
            50_75: '#FFB3B7',
            75_100: '#FF7D85',
            100_1000: '#FC535D',
            1000: '#FF323E'
        },
        negative: {
            50: '#FFF6F7',
            1: '#FFECED',
            4: '#FF757C',
            5: '#F23842',
            6: '#CE1E29',
            7: '#9D1119',
            8: '#740F15'
        },
        information: {
            1: '#E7F7FF',
            2: '#C7ECFF',
            5: '#1AA6F0',
            6: '#007DBF',
            7: '#005E8F'
        },
        positive: {
            50: '#F3FFF8',
            1: '#DFFFEC',
            4: '#3FDE7F',
            5: '#05C76A',
            6: '#0CAB5F',
            7: '#0B854A'
        },
        url: {
            default: '#0093D3'
        }
    },
    fonts: {
        AvenirLight: "'Avenir LT W01_35 Light1475496', sans-serif",
        AvenirLightItalic: "'Avenir LT W01_35 Light 1475502', sans-serif",
        AvenirBook: "'Avenir LT W01_45 Book1475508', sans-serif",
        AvenirBookItalic: "'Avenir LT W01_45 Book O1475514', sans-serif",
        AvenirRoman: "'Avenir LT W01_55 Roman1475520', sans-serif",
        AvenirItalic: "'Avenir LT W01_55 Obliqu1475526', sans-serif",
        AvenirMedium: "'Avenir LT W01_65 Medium1475532', sans-serif",
        AvenirMediumItalic: "'Avenir LT W01_65 Medium1475538', sans-serif",
        AvenirHeavy: "'Avenir LT W05_85 Heavy', sans-serif",
        AvenirHeavyItalic: "'Avenir LT W01_85 Heavy 1475550', sans-serif",
        AvenirBlack: "'Avenir LT W01_95 Black1475556', sans-serif",
        AvenirBlackItalic: "'Avenir LT W01_95 Black 1475562', sans-serif"
    },
    screen: {
        xs: '576px',
        sm: '768px',
        md: '992px',
        lg: '1200px',
        xl: '1400px',
        xxl: '1600px'
    }
}

const Theme: React.FC = ({children}) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme