import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IFetchAllAccountManagersAndMarketersResponse, TFilterType} from "../common/types";
import {NONE} from "../../const";
import {MarketerTierType, TMarketerTier} from "../../../../../../common/models/marketer-tier.type";
import {IMergedPartner} from "../../../../../../common/hooks/queries/useMergedPartnerQuery";
import {
    INonMergedPartner,
    INonMergedPartnerTags
} from "../../../../../../common/hooks/queries/useNonMergedPartnerQuery";


export interface IMarketerHealthState {
    page: number,
    perPage: number,
    search: string
}

export interface ILargestMovementsState {
    page: number,
    perPage: number,
    search: string,
    scaling: boolean
}

export interface INewestPartnersState {
    page: number,
    perPage: number,
    search: string,
}
export interface ITopPartnerEngagementState {
    page: number,
    perPage: number,
    search: string,
}

export interface IEditMergingPartnerDetailsModalState {
    opened: boolean,
    partner: null | IMergedPartner,
    nonMergedPartner: INonMergedPartner | null,
    partnerId: number | null,
    isMerged?: boolean,
    platformId: number | null
}

export interface IAgencyGrowthViewInitialState {
    filterType: TFilterType,
    include: boolean,
    selectedManagers : IFetchAllAccountManagersAndMarketersResponse[],
    selectedTiers: TMarketerTier[],
    agencyGMColumnHidden: boolean,
    largestMovements: ILargestMovementsState,
    marketerHealth: IMarketerHealthState,
    newestPartners: INewestPartnersState,
    editMergingPartnerDetailsModalState: IEditMergingPartnerDetailsModalState,
    topPartnerEngagementState: ITopPartnerEngagementState
}

const initialState : IAgencyGrowthViewInitialState = {
    filterType: NONE,
    include: true,
    selectedManagers: [],
    selectedTiers: [],
    agencyGMColumnHidden: true,
    largestMovements: {
        page: 1,
        perPage: 5,
        search: '',
        scaling: true
    },
    marketerHealth: {
        page: 1,
        perPage: 10,
        search: '',
    },
    newestPartners: {
        page: 1,
        perPage: 5,
        search: '',
    },
    editMergingPartnerDetailsModalState: {
        opened: false,
        partner: null,
        partnerId: null,
        isMerged: false,
        platformId: null,
        nonMergedPartner: null
    },
    topPartnerEngagementState: {
        page: 1,
        perPage: 5,
        search: '',
    }
}

const agencyGrowthViewSlice = createSlice({
    name: 'agencyGrowthViewSlice',
    initialState,
    reducers: {
        setFilterType: (state : IAgencyGrowthViewInitialState, action : PayloadAction<TFilterType>) =>{
            state.filterType = action.payload
            state.selectedTiers = []
            state.selectedManagers = []
        },
        setIncludeExclude: (state: IAgencyGrowthViewInitialState, action: PayloadAction<boolean>) => {
            state.include = action.payload
        },
        addOrRemoveFilterManager: (state: IAgencyGrowthViewInitialState, action: PayloadAction<IFetchAllAccountManagersAndMarketersResponse>) => {
            if(state.selectedManagers.some(manager => manager.id === action.payload.id)){
                state.selectedManagers = state.selectedManagers.filter(manager => manager.id !== action.payload.id)
            } else {
                state.selectedManagers.push(action.payload)
            }
        },
        addOrRemoveFilterMarketerTier: (state: IAgencyGrowthViewInitialState, action: PayloadAction<TMarketerTier>) => {
            if(state.selectedTiers.some(tier => tier.id === action.payload.id)){
                const index = state.selectedTiers.findIndex(tier => tier.id === action.payload.id);
                if (index !== -1) {
                    state.selectedTiers.splice(index, 1);
                }
            } else {
                state.selectedTiers = [...state.selectedTiers, action.payload]
            }
        },
        clearSelected: (state: IAgencyGrowthViewInitialState) => {
            state.selectedManagers = []
            state.selectedTiers = []
            state.filterType = "none"
            state.include = true
        },
        setIsAgencyGMColumnHidden: (state: IAgencyGrowthViewInitialState, action: PayloadAction<boolean>) => {
            state.agencyGMColumnHidden = action.payload
        },
        setLargestMovementsState: (state: IAgencyGrowthViewInitialState, action: PayloadAction<Partial<ILargestMovementsState>>) => {
            state.largestMovements = {...state.largestMovements, ...action.payload}
        },
        setMarketerHealthState: (state: IAgencyGrowthViewInitialState, action: PayloadAction<Partial<IMarketerHealthState>>) => {
            state.marketerHealth = {...state.marketerHealth, ...action.payload}
        },
        setNewestPartnersState: (state: IAgencyGrowthViewInitialState, action: PayloadAction<Partial<INewestPartnersState>>) => {
            state.newestPartners = {...state.newestPartners, ...action.payload}
        },
        setEditMergingPartnerDetailsModalState: (state: IAgencyGrowthViewInitialState, action: PayloadAction<IEditMergingPartnerDetailsModalState>) => {
            state.editMergingPartnerDetailsModalState = {...state.editMergingPartnerDetailsModalState, ...action.payload}
        },
        setTopPartnerEngagementState: (state: IAgencyGrowthViewInitialState, action: PayloadAction<Partial<ITopPartnerEngagementState>>) => {
            state.topPartnerEngagementState = {...state.topPartnerEngagementState, ...action.payload}
        }
    }
})

export const {
    setFilterType,
    setIncludeExclude,
    addOrRemoveFilterManager,
    clearSelected,
    setIsAgencyGMColumnHidden,
    addOrRemoveFilterMarketerTier,
    setLargestMovementsState,
    setMarketerHealthState,
    setNewestPartnersState,
    setEditMergingPartnerDetailsModalState,
    setTopPartnerEngagementState
} = agencyGrowthViewSlice.actions

export default agencyGrowthViewSlice.reducer