import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IFetchOverviewDataProps} from "./hooks/useActivityFeedData";

export interface IMarketerDashboardViewInitialState {
    activityFeedParams: IFetchOverviewDataProps
}

const initialState : IMarketerDashboardViewInitialState = {
    activityFeedParams: {
        paginate_by: 5,
        type: 'all',
    }
}

const DashboardSlice = createSlice({
    name: 'marketerDashboardSlice',
    initialState,
    reducers: {
        setActivityFeedParams: (state: IMarketerDashboardViewInitialState, action: PayloadAction<IFetchOverviewDataProps>) => {
            state.activityFeedParams = action.payload
        }
    }
});

export const {
    setActivityFeedParams,
} = DashboardSlice.actions

export default DashboardSlice.reducer