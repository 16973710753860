import React, {lazy, Suspense, useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import 'react-toastify/dist/ReactToastify.css';

// @ts-ignore
import LogRocket from "logrocket";
import setupLogRocketReact from 'logrocket-react';
import ReactTooltip from "react-tooltip";

import {useAppDispatch, useAppSelector} from "./common/hooks";
import {getAuth} from "./store/auth.slice";
import store from "./store/store";
import {setUpApiInterceptor} from "./common/services/apiInterceptor";

import {
    ACCOUNT,
    AGENCY_MARKETERS,
    AUTOMATED_ADJUSTMENTS,
    BANNER,
    CONTACTS,
    DASHBOARD,
    DYNAMIC,
    EMPTY,
    EVENTS,
    EVENTS_ADJUSTMENT,
    FEATURES,
    METIS,
    NOTIFICATIONS,
    PASSWORD,
    PAYOUTS,
    ROOT,
    SCHEDULED_REPORTS,
    SCHEDULED_REPORTS_LOG,
    TOOLS,
    TWO_FA,
    TAGS,
    VISIBILITY_PRESETS,
    TRACKING_PIXELS, 
    REPORTS,
    BUDGETS_REPORT
} from "./routes/Routes";
import {
    BUDGET,
    CREATIVES,
    LAND_AND_TRACK,
    OFFER_INFO,
    OUTCOME_FUNNEL,
    SCHEDULING,
    TARGETING,
    TESTING,
    TRACKING_CODE,
    TRACKING_SYSTEM
} from "./modules/advertiser/pages/create-offer/interfaces/interfaces";
import {ToastStyled} from "./common/styled-components/ToastStyled";
import {Slide} from "react-toastify";
import {IconClose, ToastErrorIcon, ToastSuccessIcon} from "./assets/svgs/SvgIcons";
import {PageLoading} from "./common/styled-components/PageLoading";
const PrivateRoute  = lazy(() => import( "./PrivateRoute"))

// Auth
const Verify = lazy(() => import("./pages/auth/two-factor-authentication/2fa-verify/Verify"));
const AddDevice = lazy(() => import("./pages/auth/two-factor-authentication/2fa-add-device/AddDevice"));



//Admin
const Advertisers = lazy(() => import( "./modules/admin/pages/advertisers/Advertisers"))
const Home = lazy(() => import( "./modules/admin/pages/advertisers-edit/home/Home"))
const Cards = lazy(() => import( "./modules/admin/pages/advertisers-edit/cards/Cards"))
const Contacts = lazy(() => import( "./modules/admin/pages/advertisers-edit/contacts/Contacts"))
const AdvertisersEdit = lazy(() => import( "./modules/admin/pages/advertisers-edit/AdvertisersEdit"))
const Password = lazy(() => import( "./modules/admin/pages/advertisers-edit/password/Password"))
const Credit = lazy(() => import( "./modules/admin/pages/advertisers-edit/billing/Billing"))
const Transparency = lazy(() => import( "./modules/admin/pages/advertisers-edit/transparency/Transparency"))
const Dashboard = lazy(() => import( "./modules/admin/pages/dashboard/Dashboard"))
const Offers = lazy(() => import( "./modules/admin/pages/offers/offers/Offers"))
const Merging = lazy(() => import( "./modules/admin/pages/partners/partner_merging/Merging"))
const CustomDomainAdmin = lazy(() => import( "./modules/admin/pages/custom-domains/CustomDomain"))
const LeapPartners = lazy(() => import( "./modules/admin/pages/partners/leap-partners/LeapPartners"))
const Billing = lazy(() => import( './modules/admin/pages/billing/Billing'))
const PartnerPayments = lazy(() => import( "./modules/admin/pages/billing/partner-payments/PartnerPayments"))
const MarketerFundingMonitoring= lazy(() => import( "./modules/admin/pages/billing/marketer-funding-monitoring/MarketerFundingMonitoring"))
const MarketerBalance = lazy(() => import( "./modules/admin/pages/billing/marketer-balance/MarketerBalance"))
const MarketersTransactions = lazy(() => import( "./modules/admin/pages/billing/marketers-transactions/MarketersTransactions"))
const PartnerTransactions = lazy(() => import( "./modules/admin/pages/billing/partner-transactions/PartnerTransactions"))
const Users = lazy(() => import( "./modules/admin/pages/users/Users"))
const VerticalGroups = lazy(() => import("./modules/admin/pages/verticals/vertical-groups/VerticalGroups"));
const Verticals = lazy(() => import("./modules/admin/pages/verticals/verticals/Verticals"));
const Tools = lazy(() => import( "./modules/admin/pages/tools/Tools"));
const Payouts = lazy(() => import( "./modules/admin/pages/tools/payouts/Payouts"));
const ScheduledReports = lazy(() => import( "./modules/admin/pages/tools/scheduled-reports/ScheduledReports"));
const ScheduledReportsLog = lazy(() => import( "./modules/admin/pages/tools/scheduled-reports/ScheduledReportsLog"));
const PartnerRegistration = lazy(() => import("./modules/admin/pages/partner-registration/PartnerRegistration"));
const  Notifications  = lazy(() => import("./modules/admin/pages/tools/notifications/Notifications"));
const Tags = lazy(() => import("./modules/admin/pages/tools/tags/Tags"));
const VisibilityPresets = lazy(() => import("./modules/admin/pages/tools/visibility-presets/VisibilityPresets"));
const Features = lazy(() => import("./modules/admin/pages/tools/features/Features"));
const Banners = lazy(()=> import("./modules/admin/pages/tools/banners/Banners"))
const TrackingPixels = lazy(() => import("./modules/admin/pages/tools/tracking-pixels/TrackingPixels"));
const Events  = lazy(() => import( "./modules/admin/pages/tools/events-adjustment/events/Events"));
const AutomatedAdjustments = lazy(() => import("./modules/admin/pages/tools/events-adjustment/automated-adjustments/AutomatedAdjustments"));
const ScheduledReportsAndLogs  = lazy(() => import( "./modules/admin/pages/tools/scheduled-reports/ScheduledReportsAndLogs"));
const EventsAdjustment  = lazy(() => import( "./modules/admin/pages/tools/events-adjustment/EventsAdjustment"));
const Metis = lazy(()=> import("./modules/admin/pages/tools/metis/Metis"))
const Reports = lazy(()=> import( "./modules/admin/pages/reports/Reports"));
const BudgetsReport = lazy(()=> import( "./modules/admin/pages/reports/budgets-report/BudgetsReport"));

// Agency Marketer
const AgencyMarketerUI = lazy(() => import("./modules/agency-marketer/AgencyMarketerUI"));
const MarketerDashboard = lazy(() => import("./modules/agency-marketer/dashboard/Dashboard"));
const DynamicReportAgency = lazy(() => import("./modules/agency-marketer/dynamic-report/DynamicReport"));
const Account = lazy(() => import("./modules/agency-marketer/account/Account"));


// Partners
const Referrals = lazy(() => import("./modules/partner/pages/referrals/Referrals"));
const CustomDomainAdvertiser = lazy(() => import("./modules/advertiser/pages/custom-domains/CustomDomain"));
const CustomDomainPartner = lazy(() => import("./modules/partner/pages/custom-domains/CustomDomain"));
const TwoFactorAuthenticationPartner = lazy(() => import('./modules/partner/pages/account/two-factor-authentication/TwoFactorAuthentication'));
const DynamicReport = lazy(() => import("./modules/admin/pages/dynamic-report/DynamicReport"));

// Marketers
const TwoFactorAuthenticationMarketer = lazy(() => import("./modules/advertiser/pages/account/two-factor-authentication/TwoFactorAuthentication"));
const Cpa = lazy(() => import("./modules/advertiser/pages/offers/cpa/Cpa"));
const CreateOffers = lazy(() => import("./modules/advertiser/pages/create-offer/CreateOffers"));
const OfferType = lazy(() => import("./modules/advertiser/pages/create-offer/OfferType"));
const OfferTypeApps = lazy(() => import("./modules/advertiser/pages/create-offer/OfferTypeApps"));
const Mobile = lazy(() => import("./modules/advertiser/pages/create-offer/Mobile"));
const Main = lazy(() => import("./modules/advertiser/pages/create-offer/layout/main/Main"));
const OfferInfo = lazy(() => import("./modules/advertiser/pages/create-offer/layout/main/offer-info/OfferInfo"));
const TrackingSystem = lazy(() => import("./modules/advertiser/pages/create-offer/layout/main/tracking-system/TrackingSystem"));
const LandAndTrack = lazy(() => import("./modules/advertiser/pages/create-offer/layout/main/land-and-track/LandAndTrack"));
const OutcomeFunnel = lazy(() => import("./modules/advertiser/pages/create-offer/layout/main/outcome-funnel/OutcomeFunnel"));
const TrackingCodes = lazy(() => import("./modules/advertiser/pages/create-offer/layout/main/tracking-codes/TrackingCodes"));
const Testing = lazy(() => import("./modules/advertiser/pages/create-offer/layout/main/testing/Testing"));
const CreativesAndEmail = lazy(() => import("./modules/advertiser/pages/create-offer/layout/main/creatives-and-email/CreativesAndEmail"));
const Budget = lazy(() => import("./modules/advertiser/pages/create-offer/layout/main/budget/Budget"));
const Targeting = lazy(() => import('./modules/advertiser/pages/create-offer/layout/main/targeting/Targeting'));
const Scheduling = lazy(() => import("./modules/advertiser/pages/create-offer/layout/main/scheduling/Scheduling"));
const AgencyMarketerPassword = lazy(() => import('./modules/agency-marketer/account/password/Password'));
const AgencyMarketerContacts = lazy(() => import('./modules/agency-marketer/account/contacts/Contacts'));
const MarketerDynamicReport = lazy(() => import('./modules/advertiser/pages/dynamic-report/DynamicReport'))
const UserMarketersDashboard = lazy(() => import('./modules/advertiser/pages/dashboard/Dashboard'));
const MarketerBudgetGroups = lazy(() => import('./modules/advertiser/pages/budget-groups/BudgetGroups'));




const queryClient = new QueryClient()

const App = () => {
    const {user, config} = useAppSelector(state => state.auth)
    const { initialDelay } = useAppSelector(state => state.tooltips)
    const dispatch = useAppDispatch();

    const navigate = useNavigate()
    const location = useLocation()

    const [twoFactorAuthenticationModalInitialState, setTwoFactorAuthenticationModalInitialState] = useState(false)

    const reduxStore = store.getState

    setUpApiInterceptor(reduxStore, location.pathname)

    interface data {
        id: number,
        token?: string,
        data?: {}
    }
    useEffect(()=> {
        if (config && user) {
            let logrocketFeatureEnabled
            switch (user.user_type){
                case "admin":
                    logrocketFeatureEnabled = config.features.includes(`${user.network_id}_admin_logrocket`)
                    break;
                case "affiliate":
                    logrocketFeatureEnabled = config.features.includes(`${user.network_id}_partner_logrocket`)
                    break;
                case "advertiser":
                    logrocketFeatureEnabled = config.features.includes(`${user.network_id}_marketer_logrocket`)
                    break;
            }
            if (logrocketFeatureEnabled) {
                LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT_ID!, {
                    mergeIframes: true,
                    parentDomain: process.env.REACT_APP_API_BASE_URL,
                });
                LogRocket.log("REACT COOKIES: ", document.cookie)
                LogRocket.log("REACT LOCAL STORAGE: ", {...localStorage})
                setupLogRocketReact(LogRocket);
            }
            /**
             * exact_path is path for agency marketer UI
             */
            if (config.exact_path) {
                navigate(AGENCY_MARKETERS + ROOT + config.exact_path)
            }
        }
    },[user, config])

    window.addEventListener("message", (event: any) => {
        if (event.origin === process.env.REACT_APP_API_BASE_URL) {
            let auth_data: data = event.data;
            auth_data.id === 1 && dispatch(getAuth(auth_data));
            if(event.data.open_two_factor_verification_modal){
                setTwoFactorAuthenticationModalInitialState(event.data.open_two_factor_verification_modal)
            }
        }
    }, false);

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    return (
        <QueryClientProvider client={queryClient}>
            <ToastStyled
                icon={(props) => {
                    switch (props.type) {
                        case "success":
                            return <ToastSuccessIcon/>;
                        case 'error':
                            return <ToastErrorIcon/>
                        default:
                            return null;
                    }
                }}
                pauseOnFocusLoss={false}
                transition={Slide}
                limit={1}
                draggable={false}
                closeOnClick={false}
                closeButton={({ closeToast }) => (
                    <IconClose onClick={closeToast}/>
                )}
                autoClose={5000}
                hideProgressBar={false}
                position={'bottom-center'}
            />
            <ReactTooltip
                class={"react-tooltip-container"}
                arrowColor={"white"}
                place={"top"}
                delayShow={initialDelay}
                effect="solid"
                type={'light'}
                globalEventOff={'click'}
                multiline={true}/>
            <Suspense fallback={<PageLoading/>}>
                <Routes>
                    {/* Private route removed for agency UI in order to use Product Fruits features.
                     Htaccess will be set on subdomain of React app */}
                    <Route path={ROOT + AGENCY_MARKETERS}>
                        <Route path={EMPTY} element={<AgencyMarketerUI/>}>
                            <Route path={DASHBOARD} element={<MarketerDashboard/>}/>
                            <Route path={DYNAMIC} element={<DynamicReportAgency/>}/>
                            <Route path={ACCOUNT} element={<Account/>}>
                                <Route path={CONTACTS} element={<AgencyMarketerContacts/>}/>
                                <Route path={PASSWORD} element={<AgencyMarketerPassword/>}/>
                                <Route path={TWO_FA} element={<TwoFactorAuthenticationMarketer
                                    twoFactorModalInitialOpened={twoFactorAuthenticationModalInitialState}/>}/>
                            </Route>
                        </Route>
                    </Route>
                    <Route path="/test" element={<div>Test</div>}/>
                    <Route path={'auth'}>
                        <Route path='login'/>
                        <Route path="verify" element={<Verify/>}/>
                        <Route path="add-device" element={<AddDevice/>}/>
                    </Route>
                    <Route path="/admin" element={<PrivateRoute user_type="admin"/>}>
                        <Route path="custom-domain" element={<CustomDomainAdmin/>}/>
                        <Route path="users/marketers" element={<Advertisers/>}/>
                        <Route path="users/marketers/:id" element={<AdvertisersEdit/>}>
                            <Route path="home" element={<Home/>}/>
                            <Route path="cards" element={<Cards/>}/>
                            <Route path="contacts" element={<Contacts/>}/>
                            <Route path="password" element={<Password/>}/>
                            <Route path="credit" element={<Credit/>}/>
                            <Route path="partner-transparency" element={<Transparency/>}/>
                        </Route>
                        <Route path="vertical_groups" element={<VerticalGroups/>}/>
                        <Route path="verticals/all" element={<Verticals/>}/>
                        <Route path="users/admin" element={<Users
                            twoFactorModalInitialOpened={twoFactorAuthenticationModalInitialState}/>}/>
                        <Route path={"users/partners"} element={<LeapPartners/>}/>
                        <Route path="users/partners/merging" element={<Merging/>}>
                        </Route>
                        <Route path='billing' element={<Billing/>}>
                            <Route path='marketer-balance' element={<MarketerBalance/>}/>
                            <Route path='partner-transactions' element={<PartnerTransactions/>}/>
                            <Route path='marketer-funding-monitoring' element={<MarketerFundingMonitoring/>}/>
                            <Route path='partner-payments' element={<PartnerPayments/>}/>
                            <Route path='marketers-transactions' element={<MarketersTransactions/>}/>
                        </Route>
                        <Route path='' element={<Dashboard/>}/>
                        <Route path='dynamic' element={<DynamicReport/>}/>
                        <Route path='offers' element={<Offers/>}/>
                        <Route path='requests' element={<PartnerRegistration/>}/>

                        <Route path={TOOLS} element={<Tools/>}>
                            <Route path={PAYOUTS} element={<Payouts/>}/>
                            <Route path={NOTIFICATIONS} element={<Notifications/>} />
                            <Route path={SCHEDULED_REPORTS} element={<ScheduledReportsAndLogs/>}>
                                <Route path={''} element={<ScheduledReports/>}/>
                                <Route path={SCHEDULED_REPORTS_LOG+'/:id'} element={<ScheduledReportsLog/>}/>
                            </Route>
                            <Route path={TAGS} element={<Tags/>} />
                            <Route path={VISIBILITY_PRESETS} element={<VisibilityPresets/>} />
                            <Route path={FEATURES} element={<Features/>} />
                            <Route path={BANNER} element={<Banners/>}/>
                            <Route path={TRACKING_PIXELS} element={<TrackingPixels/>} />
                            <Route path={EVENTS_ADJUSTMENT} element={<EventsAdjustment/>}>
                                {/*@ts-ignore*/}
                                <Route path={EVENTS} element={<Events/>}/>
                                {/*@ts-ignore*/}
                                <Route path={AUTOMATED_ADJUSTMENTS} element={<AutomatedAdjustments/>}/>
                            </Route>
                            <Route path={METIS} element={<Metis/>}/>
                        </Route>
                        <Route path={REPORTS} element={<Reports/>}>
                            <Route path={BUDGETS_REPORT} element={<BudgetsReport/>}/>

                        </Route>


                    </Route>
                    <Route path="/partners" element={<PrivateRoute user_type="affiliate"/>}>
                        <Route path="referrals" element={<Referrals/>}/>
                        <Route path="custom-domain" element={<CustomDomainPartner/>}/>
                        <Route path='account' element={<PrivateRoute user_type="affiliate"/>}>
                            <Route
                                path='two-factor-authentication'
                                element={
                                    <TwoFactorAuthenticationPartner
                                        twoFactorModalInitialOpened={twoFactorAuthenticationModalInitialState}
                                    />
                                }
                            />
                        </Route>
                    </Route>
                    <Route path="/marketers" element={<PrivateRoute user_type="advertiser"/>}>
                        <Route path="dynamic" element={<MarketerDynamicReport/>}/>
                        <Route path='' element={<UserMarketersDashboard/>}/>
                        <Route path="campaigns/budget-groups" element={<MarketerBudgetGroups/>}/>
                        <Route path="referrals" element={<Referrals/>}/>
                        <Route path="custom-domain" element={<CustomDomainAdvertiser/>}/>
                        <Route path='account' element={<PrivateRoute user_type="advertiser"/>}>
                            <Route
                                path='two-factor-authentication'
                                element={
                                    <TwoFactorAuthenticationMarketer
                                        twoFactorModalInitialOpened={twoFactorAuthenticationModalInitialState}
                                    />
                                }
                            />
                        </Route>

                        {/*<Route path="offers">*/}
                        {/*    /!*<Route path="create" element={<Cpa/>}/>*!/*/}
                        {/*    <Route path="edit/:id" element={<Cpa/>} />*/}
                        {/*    <Route path="replicate/:id" element={<Cpa replicate/>} />*/}
                        {/*    <Route path="cpa">*/}
                        {/*        <Route path="create" element={<Cpa/>} />*/}
                        {/*        <Route path="edit/:id" element={<Cpa/>} />*/}
                        {/*        <Route path="replicate/:id" element={<Cpa replicate/>} />*/}
                        {/*    </Route>*/}
                        {/*</Route>*/}
                        <Route path="offers">
                            <Route path="create" element={<CreateOffers/>}>
                                <Route path="type" element={<OfferType/>}/>
                                <Route path="type/apps" element={<OfferTypeApps/>}/>
                                <Route path="type/apps/mobile" element={<Mobile/>}/>
                                <Route path="i" element={<Main/>}>
                                    <Route path={OFFER_INFO} element={<OfferInfo/>}/>
                                    <Route path={TRACKING_SYSTEM} element={<TrackingSystem/>}/>
                                    <Route path={LAND_AND_TRACK} element={<LandAndTrack/>}/>
                                    <Route path={OUTCOME_FUNNEL} element={<OutcomeFunnel/>}/>
                                    <Route path={TRACKING_CODE} element={<TrackingCodes/>}/>
                                    <Route path={TESTING} element={<Testing/>}/>
                                    <Route path={CREATIVES} element={<CreativesAndEmail/>}/>
                                    <Route path={BUDGET} element={<Budget/>}/>
                                    <Route path={TARGETING} element={<Targeting/>}/>
                                    <Route path={SCHEDULING} element={<Scheduling/>}/>
                                </Route>
                            </Route>
                            <Route path="edit/:id" element={<CreateOffers/>}>
                                <Route path="type/apps/mobile" element={<Mobile/>}/>
                                <Route path="i" element={<Main/>}>
                                    <Route path={OFFER_INFO} element={<OfferInfo/>}/>
                                    <Route path={TRACKING_SYSTEM} element={<TrackingSystem/>}/>
                                    <Route path={LAND_AND_TRACK} element={<LandAndTrack/>}/>
                                    <Route path={OUTCOME_FUNNEL} element={<OutcomeFunnel/>}/>
                                    <Route path={TRACKING_CODE} element={<TrackingCodes/>}/>
                                    <Route path={TESTING} element={<Testing/>}/>
                                    <Route path={CREATIVES} element={<CreativesAndEmail/>}/>
                                    <Route path={BUDGET} element={<Budget/>}/>
                                    <Route path={TARGETING} element={<Targeting/>}/>
                                    <Route path={SCHEDULING} element={<Scheduling/>}/>
                                </Route>
                            </Route>
                            <Route path="draft/:id" element={<CreateOffers draft/>}>
                                <Route path="i" element={<Main draft/>}>
                                    <Route path={OFFER_INFO} element={<OfferInfo/>}/>
                                    <Route path={TRACKING_SYSTEM} element={<TrackingSystem/>}/>
                                    <Route path={LAND_AND_TRACK} element={<LandAndTrack/>}/>
                                    <Route path={OUTCOME_FUNNEL} element={<OutcomeFunnel/>}/>
                                    <Route path={TRACKING_CODE} element={<TrackingCodes/>}/>
                                    <Route path={TESTING} element={<Testing/>}/>
                                    <Route path={CREATIVES} element={<CreativesAndEmail/>}/>
                                    <Route path={BUDGET} element={<Budget/>}/>
                                    <Route path={TARGETING} element={<Targeting/>}/>
                                    <Route path={SCHEDULING} element={<Scheduling/>}/>
                                </Route>
                            </Route>
                            <Route path="replicate/:id" element={<CreateOffers replicate/>}>
                                <Route path="type" element={<OfferType/>}/>
                                <Route path="type/apps" element={<OfferTypeApps/>}/>
                                <Route path="type/apps/mobile" element={<Mobile/>}/>
                                <Route path="i" element={<Main replicate/>}>
                                    <Route path={OFFER_INFO} element={<OfferInfo/>}/>
                                    <Route path={TRACKING_SYSTEM} element={<TrackingSystem/>}/>
                                    <Route path={LAND_AND_TRACK} element={<LandAndTrack/>}/>
                                    <Route path={OUTCOME_FUNNEL} element={<OutcomeFunnel/>}/>
                                    <Route path={TRACKING_CODE} element={<TrackingCodes/>}/>
                                    <Route path={TESTING} element={<Testing/>}/>
                                    <Route path={CREATIVES} element={<CreativesAndEmail/>}/>
                                    <Route path={BUDGET} element={<Budget/>}/>
                                    <Route path={TARGETING} element={<Targeting/>}/>
                                    <Route path={SCHEDULING} element={<Scheduling/>}/>
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Routes>
            </Suspense>
            <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'}/>
        </QueryClientProvider>
    );
}
export default App




