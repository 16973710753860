import {DatePresetsMap} from "./models/datePresets";

export const DATE_PRESETS: DatePresetsMap = {
    'all': {
        value: 'all',
        name: 'All',
    },
    'today': {
        value: 'today',
        name: 'Today',
        type: 'weekly'
    },
    'yesterday': {
        value: 'yesterday',
        name: 'Yesterday',
        type: 'weekly'
    },
    'week_to_date': {
        value: 'week_to_date',
        name: 'Week to Date',
        type: 'weekly'
    },
    'this_week': {
        value: 'this_week',
        name: 'This Week',
        type: 'weekly'
    },
    'last_7_days': {
        value: 'last_7_days',
        name: 'Last 7 days',
        type: 'weekly'
    },
    'last_week': {
        value: 'last_week',
        name: 'Last Week',
        type: 'weekly'
    },
    'this_month': {
        value: 'this_month',
        name: 'Month to Date',
        type: 'monthly'
    },
    'month_to_date': {
        value: 'month_to_date',
        name: 'Month to Date',
        type: 'monthly'
    },
    'month_to_yesterday': {
        value: 'month_to_yesterday',
        name: 'Month to Yesterday',
        type: 'monthly'
    },
    'last_30_days': {
        value: 'last_30_days',
        name: 'Last 30 days',
        type: 'monthly'
    },
    'last_month': {
        value: 'last_month',
        name: 'Last Month',
        type: 'monthly'
    },
    'quarter_to_date': {
        value: 'quarter_to_date',
        name: 'Quarter to Date',
        type: 'quarterly'
    },
    'last_quarter': {
        value: 'last_quarter',
        name: 'Last Quarter',
        type: 'quarterly'
    },
    'this_year': {
        value: 'this_year',
        name: 'Year to Date',
        type: 'yearly'
    },
    'year_to_date': {
        value: 'year_to_date',
        name: 'Year to Date',
        type: 'yearly'
    },
    'year_to_yesterday': {
        value: 'year_to_yesterday',
        name: 'Year to Yesterday',
        type: 'yearly'
    },
    'year_to_last_full_month': {
        value: 'year_to_last_full_month',
        name: 'Year to Last Full Month',
        type: 'yearly'
    },
    'last_year': {
        value: 'last_year',
        name: 'Last Year',
        type: 'yearly'
    },
    'previous_period': {
        value: 'previous_period',
        name: 'Previous Period',
    },
    'previous_year': {
        value: 'previous_year',
        name: 'Previous Year',
    },
    'previous_year_dow': {
        value: 'previous_year_dow',
        name: 'Previous Year by Matching DoW',
    },
    'previous_full_year': {
        value: 'previous_full_year',
        name: 'Previous Full Year',
    },
    'custom': {
        value: 'custom',
        name: 'Custom',
    }

}

