import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPreset} from "../../../../../common/components/range-date-picker/presets/presets.props";
import {TDateSelector} from "../../dashboard/agency-view/AgencyView.slice";
import {IFetchCampaignsResponse} from "./Offers.service";
import { allOffersPaginationColumnsList } from "./OffersTable/offersPaginationColumnsList";
import {IFilter} from "../../../../../store/userPreferences.slice";

export interface IOffersInitialState {
    date_selector: TDateSelector,
    date_from: Date | null | undefined,
    date_to: Date | null | undefined,
    date_range_preset: IPreset,
    offers_search_value: string,
    per_page: number,
    campaign: IFetchCampaignsResponse // whole campaign object for dynamic report
}

const initialState: IOffersInitialState = {
    date_selector: 'week',
    date_from: null,
    date_to: null,
    date_range_preset: {
        value: "this_month",
        name: 'Month to Date'
    },
    offers_search_value: '',
    per_page: 50,
    campaign: {} as IFetchCampaignsResponse
}

const offersSlice = createSlice({
    name: 'offersPageSlice',
    initialState,
    reducers: {
        setOffersDateSelector: (state: IOffersInitialState, action: PayloadAction<TDateSelector>) => {
            state.date_selector = action.payload
        },
        setOffersDateFrom: (state: IOffersInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.date_from = action.payload
        },
        setOffersDateTo: (state: IOffersInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.date_to = action.payload
        },
        setOffersDateRangePreset: (state: IOffersInitialState, action:PayloadAction<IPreset>) => {
            state.date_range_preset = action.payload
        },
        setOffersSearchValue: (state: IOffersInitialState, action: PayloadAction<string>) => {
            state.offers_search_value = action.payload
        },

        setPerPage: (state: IOffersInitialState, action: PayloadAction<number>) => {
            state.per_page = action.payload
        },
        setCampaign: (state: IOffersInitialState, action: PayloadAction<IFetchCampaignsResponse>) => {
            state.campaign = action.payload
        }
    }
})

export default offersSlice.reducer
export const {
    setOffersDateFrom,
    setOffersDateRangePreset,
    setOffersDateSelector,
    setOffersDateTo,
    setOffersSearchValue,
    setPerPage,
    setCampaign
} = offersSlice.actions
